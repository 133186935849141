import React from 'react';
import { bool, node, shape } from 'prop-types';

import { LoadingButton as MuiLoadingButton } from '@mui/lab';

const LoadingButton = ({ loading, disabled, children, sx, ...rest }) => (
  <MuiLoadingButton
    variant='contained'
    disabled={disabled}
    loading={loading}
    loadingPosition='end'
    sx={{
      ...sx,
      height: 50,
      '&.MuiLoadingButton-loading': {
        background: (theme) => theme.palette.neutral.light,
        color: (theme) => theme.palette.neutral.darkest,
      },
      '&.Mui-disabled': {
        background: (theme) => theme.palette.neutral.lighter,
        color: (theme) => theme.palette.neutral.medium,
      },
    }}
    {...rest}
  >
    {children}
  </MuiLoadingButton>
);

LoadingButton.propTypes = {
  loading: bool,
  disabled: bool,
  children: node,
  sx: shape({}),
};

LoadingButton.defaultProps = {
  loading: false,
  disabled: false,
  children: null,
  sx: {},
};

export default LoadingButton;

import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { Global, css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles({
  '@keyframes animate': {
    '0%, 40%, 100% ': {
      height: 10,
      width: 10,
    },
    '20%': {
      height: 14,
      width: 14,
      background: '#6994F4',
    },
  },
  dotsContainer: {
    '& div:nth-child(1)': {
      animationDelay: '-1.4s',
    },
    '& div:nth-child(2)': {
      animationDelay: '-1.2s',
    },
    '& div:nth-child(3)': {
      animationDelay: '-1.0s',
    },
    '& div:nth-child(4)': {
      animationDelay: '-.8s',
    },
    height: '25px',
  },
  dot: {
    margin: '8px',
    background: '#E1EAF1',
    borderRadius: '50%',
    animationName: '$animate',
    animationDuration: '1.6s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  },
});

const LoadingIndicator = ({ message, loadingReason }) => {
  const classes = useStyles();
  const DEFAULT_TIMER = 30000; // 30 seconds

  useEffect(() => {
    let tempTimer = DEFAULT_TIMER;
    const interval = setInterval(() => {
      if (tempTimer < 1000) {
        throw new Error(`Loading timeout error: ${loadingReason}`);
      } else tempTimer -= 1000;
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='70vh'>
      <Global
        styles={css`
          html,
          body {
            height: 100%;
          }
        `}
      />
      <Box className={classes.dotsContainer} display='flex' flexDirection='row' justifyContent='center'>
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </Box>
      {message && (
        <Box mt={4}>
          <Typography variant='body1' sx={{ color: (theme) => theme.palette.text.secondary }}>
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

LoadingIndicator.propTypes = {
  message: string,
  loadingReason: string,
};

LoadingIndicator.defaultProps = {
  message: null,
  loadingReason: '',
};

export default LoadingIndicator;

import React, { createRef, useEffect, useState } from 'react';
import { bool, shape, string } from 'prop-types';

import { FormControl, FormHelperText, InputLabel, Input as MuiInput, OutlinedInput } from '@mui/material';

const Input = ({ helperText, label, error, inputProps, outlined, ...rest }) => {
  const ref = createRef();
  const [hasError, setHasError] = useState({
    error: Boolean(error),
    text: error,
  });

  const handleBlur = (val) => {
    if (val) {
      const currentRef = ref.current;
      const currentRefValid = currentRef?.checkValidity();
      setHasError({
        error: !currentRefValid,
        text: currentRefValid ? '' : helperText,
      });
    } else {
      setHasError({
        error: Boolean(error),
        text: error,
      });
    }
  };

  const inputComponent = outlined ? (
    <OutlinedInput
      {...rest}
      inputRef={ref}
      notched
      inputProps={{
        ...inputProps,
        onBlur: (e) => handleBlur(e.target.value),
      }}
      error={hasError.error}
      color='secondary'
    />
  ) : (
    <MuiInput
      {...rest}
      inputRef={ref}
      inputProps={{
        ...inputProps,
        onBlur: (e) => handleBlur(e.target.value),
      }}
      error={hasError.error}
      color='secondary'
    />
  );
  useEffect(() => {
    setHasError({
      error: Boolean(error),
      text: error,
    });
  }, [error]);
  return (
    <FormControl variant={outlined ? 'outlined' : 'standard'} fullWidth>
      <InputLabel
        error={hasError.error}
        shrink={Boolean(rest.value)}
        required={Boolean(rest.required)}
        color='secondary'
        sx={{ backgroundColor: 'neutral.lightest' }}
      >
        {label}
      </InputLabel>
      {inputComponent}
      {hasError.text && <FormHelperText error>{hasError.text} </FormHelperText>}
    </FormControl>
  );
};

Input.propTypes = {
  label: string.isRequired,
  error: string,
  inputProps: shape({}),
  helperText: string,
  outlined: bool,
};

Input.defaultProps = {
  error: '',
  inputProps: {},
  helperText: '',
  outlined: false,
};

export default Input;

import React from 'react';
import { oneOf, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Alert = ({ text, type }) => {
  const theme = {
    error: {
      color: 'accent.main',
    },
  };
  return (
    <Box display='flex' alignItems='center'>
      <InfoOutlinedIcon color={type} />
      <Typography ml={1} lineHeight='1.3' variant='body2' color={theme[type].color}>
        {text}
      </Typography>
    </Box>
  );
};

Alert.propTypes = {
  text: string.isRequired,
  type: oneOf(['error']).isRequired,
};

export default Alert;

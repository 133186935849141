import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Divider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 3,
  left: 'calc(50% - 15px)',
}));

export default Divider;

import React from 'react';
import { func, node } from 'prop-types';

const Form = ({ onValid, onInvalid, children, customValidity, ...rest }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity() && customValidity()) {
      onValid(e);
    } else {
      onInvalid();
    }
  };
  return (
    <form style={{ width: '100%' }} onSubmit={(e) => handleSubmit(e)} noValidate {...rest}>
      {children}
    </form>
  );
};

Form.propTypes = {
  onValid: func.isRequired,
  onInvalid: func,
  children: node,
  customValidity: func,
};

Form.defaultProps = {
  children: null,
  onInvalid: () => {},
  customValidity: () => true,
};
export default Form;
